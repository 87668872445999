
html {
    width: 100%;
    height: 100%;
    scroll-behavior:smooth;
}

body {
    font-family: "Ubuntu","Helvetica Neue","Helvetica,Arial","sans-serif";
    overflow:auto;
    font-size:20px;
    background-image:url(img/dusk.webp);
    
    margin: 0;
    padding: 0;
    /* background: rgb(230,230,230); */
    
    color: rgb(50,50,50);
    font-family: 'Open Sans', sans-serif;
    font-size: 112.5%;
    line-height: 1.6em;
}
/* "Reenie Beanie",arial,sans-serif */

.text-vertical-center {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.text-vertical-top {
    padding: 20px;
    text-align: center;
    vertical-align: top;
    width: 100%;
    height: 80vh;
}

.text-vertical-bottom {
    text-align: center;
    vertical-align: bottom;
    width: 100%;
}

.recent-display-area {
    padding: 20px 20px;
    /* display: table-cell; */
    text-align: left;
    vertical-align: top;
    color: white;
}
.text-heading-color {
    color: #ff9900;
    text-shadow: 2px 2px #ffffff;
}

.text-body-color {
    color: #ffffff;
    text-align: center;
    font-size: 32px;
    height: 32px;
    line-height: 32px;
}



.text-vertical-top h1 {
    font-size: 4.5em;
    font-weight: 600;
}
.text-vertical-center h1 {
    margin: 0;
    padding: 0;
    font-size: 4.5em;
    font-weight: 700;
}

/* Custom Button Styles */

.btn-dark {
    border-radius: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.93);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
    color: #fff;
    background-color: rgba(86, 156, 132, 0.7);
}

.btn-light {
    border-radius: 0;
    color: #333;
    background-color: rgb(255,255,255);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
    color: #333;
    background-color: rgba(255,255,255,0.8);
}
.section-content {
    padding: 45px;
    width: 90%;
}

/* Custom Horizontal Rule */

hr.small {
    max-width: 100px;
}


/* About */

.section-gap {
    padding: 50px 0;
}
.element-hide {
    display: none;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
}
.element-show {
    display: block;
    opacity: 1;
}

/* Services */

.services {
    padding: 50px 0;
}

.service-item {
    margin-bottom: 30px;
}

/* Portfolio */

.portfolio {
    display: none;
    padding: 50px 0;
}

.portfolio-item {
    margin-bottom: 30px;
}

.img-portfolio {
    margin: auto;
    opacity: 0.5;
}

.img-portfolio:hover {
    opacity: 1;
    transform: scale(1.1);
    border: 1px solid red;
}

/* Call to Action */

.call-to-action {
    padding: 50px 0;
}

.call-to-action .btn {
    margin: 10px;
}

/* Map */

.map {
    height: 500px;
}

@media(max-width:768px) {
    .map {
        height: 75%;
    }
}

.sk-rotating-plane {
    background-color: #333;
    -webkit-animation: sk-rotatePlane 1.2s;
    animation: sk-rotatePlane 1.2s;
}

@-webkit-keyframes sk-rotatePlane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes sk-rotatePlane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/* Modal Body*/

.fixedbutton {
    position: fixed;
    display: none;
    bottom: 10px;
    right: 10px;
}

.modal-body{
    font-family:"sans-serif";
    font-size: 15px;
}

/*text animation*/
.box {
    border:1px solid #E38E34;
    background-color: #FFE7BF;
    height:100px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

#title {
    margin:5px;
    border-bottom:1px solid #E38E34;
    color:#C46908;
    font-weight:bold;
}
#message {
    margin:5px;
}

/* blinking cursor */
.blink-cursor {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* tech image icons */
.tech-icon {
    animation: icon-rotate 0.5s;
    display: inline-block;
    position: relative;
    left: 0;
}

@keyframes icon-rotate {
    0% {
        -webkit-transform: perspective(120px) rotateY(0deg);
        transform: perspective(120px) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(-180.1deg);
        transform: perspective(120px) rotateY(-180.1deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(-180deg);
        transform: perspective(120px) rotateY(-180deg);
    }
}

/* stickers notes */
.unorder-list, .unorder-list-item {
  list-style:none;
}

.unorder-list {
  overflow:hidden;
  padding:10px;
}

.unorder-list .unorder-list-item a {
  text-decoration:none;
  color:#000;
  background:#ffc;
  display:block;
  height:12em;
  width:12em;
  padding:1em;
  -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
  -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  -moz-transition:-moz-transform .15s linear;
  -o-transition:-o-transform .15s linear;
  -webkit-transition:-webkit-transform .15s linear;
}

.unorder-list .unorder-list-item {
  margin:1em;
  float:left;
}

.unorder-list .unorder-list-item h2 {
  font-size:110%;
  font-weight:bold;
  padding-bottom:10px;
}

.unorder-list .unorder-list-item p {
  font-family:"Reenie Beanie",arial,sans-serif;
  font-size:110%;
}

.unorder-list .unorder-list-item a {
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform:rotate(-6deg);
}

.unorder-list .unorder-list-item:nth-child(even) a {
  -o-transform:rotate(4deg);
  -webkit-transform:rotate(4deg);
  -moz-transform:rotate(4deg);
  position:relative;
  top:5px;
  background:#cfc;
}

.unorder-list .unorder-list-item:nth-child(3n) a {
  -o-transform:rotate(-3deg);
  -webkit-transform:rotate(-3deg);
  -moz-transform:rotate(-3deg);
  position:relative;
  top:-5px;
  background:#ccf;
}

.unorder-list .unorder-list-item:nth-child(5n) a {
  -o-transform:rotate(5deg);
  -webkit-transform:rotate(5deg);
  -moz-transform:rotate(5deg);
  position:relative;
  top:-10px;
}

.unorder-list .unorder-list-item a:hover,.unorder-list .unorder-list-item a:focus {
  box-shadow:10px 10px 7px rgba(0,0,0,.7);
  -moz-box-shadow:10px 10px 7px rgba(0,0,0,.7);
  -webkit-box-shadow: 10px 10px 7px rgba(0,0,0,.7);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  position:relative;
  z-index:5;
}

/* section elements */

/* the landing element */

.landing-section {
    display: table;
    position: relative;
    padding: 50px 0 0 0;
    width: 100%;
    height: 100vh;
    background: #181515;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.personal-section {
    position: relative;
    width: 100%;
    height: 100vh;
}

.career-section {
    position: relative;
    width: 100%;
}

/* nav bar customization */
.cus-navbar {
    position: fixed;
    z-index: 100;
    min-height:30px !important;
    width:100% !important;
}

.navbar-nav > li > a, .navbar-brand {
    padding-top:5px !important;
    padding-bottom:0 !important;
    height: 30px;
}
.navbar-nav > li > form {
    height: 30px;
}

.navbar-nav > li > form > .btn-link {
    height: 30px;
}

/* clock */
.clock-box {
    position:relative;text-align:center; font-size:20px;
}
.clock-box a {
    font-size:24px;text-decoration:none;
}

.clock {
    margin:auto;
}
.clock .digit {
    font-size:34px;
}
/* corasuol */
.gallery-slider {
    width:70%;
    margin-left:auto;
    margin-right:auto;
    padding-bottom:20px;
}

/* Footer */

.footer {
    display: table;
    padding: 60px 0;
    color: #ffffff;
    background-color: #181515;
    width: 100%;
}

.footer a {
    color: #ffffff;
}

/* career list */
/* rectangle list */

.rectangle-list a{
    position: relative;
    display: block;
    padding: .4em .4em .4em .8em;
    *padding: .4em;
    margin: .5em 0 .5em 2.5em;
    background: #ddd;
    color: #444;
    text-decoration: none;
    transition: all .3s ease-out;
}

.rectangle-list a:hover{
    background: #eee;
}

.rectangle-list a:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -2.5em;
    top: 50%;
    margin-top: -1em;
    background: #fa8072;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
}

.rectangle-list a:after{
    position: absolute;
    content: '';
    border: .5em solid transparent;
    left: -1em;
    top: 50%;
    margin-top: -.5em;
    transition: all .3s ease-out;
}

.rectangle-list a:hover:after{
    left: -.5em;
    border-left-color: #fa8072;
}

/* circle list */

.circle-list li{
    padding: 2.5em;
    border-bottom: 1px dashed #ccc;
}

.circle-list h2{
    position: relative;
    margin: 0;
}

.circle-list p{
    margin: 0;
}

.circle-list h2:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;
    z-index: -1;
    left: -1.3em;
    top: -.8em;
    background: #f5f5f5;
    height: 1.5em;
    width: 1.5em;
    border: .1em solid rgba(0,0,0,.05);
    text-align: center;
    font: italic bold 1em/1.5em Georgia, Serif;
    color: #ccc;
    border-radius: 1.5em;
    transition: all .2s ease-out;
}

.circle-list li:hover h2:before{
    background-color: #ffd797;
    border-color: rgba(0,0,0,.08);
    border-width: .2em;
    color: #444;
    transform: scale(1.5);
}

/* rounded list */

.rounded-list span{
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    *padding: .4em;
    margin: .5em 0;
    background: #ddd;
    color: #444;
    text-decoration: none;
    border-radius: .3em;
    transition: all .3s ease-out;
}

.rounded-list span:hover{
    background: #eee;
}

.rounded-list span:hover:before{
    transform: rotate(360deg);
}

.rounded-list span:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background: #87ceeb;
    height: 2em;
    width: 2em;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all .3s ease-out;
}

.timeline {
    position: relative;
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
}
  
.timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(80,80,80);
    background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);

    z-index: 5;
}

.timeline li {
    padding: 1em 0;
    box-sizing: unset;
}

.timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.direction-l {
    position: relative;
    width: 300px;
    float: left;
    text-align: right;
}

.direction-r {
    position: relative;
    width: 300px;
    float: right;
    text-align: left;
}

.flag-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}
  
.flag {
    position: relative;
    display: inline;
    background: rgb(248,248,248);
    padding: 6px 10px;
    border-radius: 5px;
    
    font-weight: 600;
}
  
.direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}
  
.direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}
  
.direction-l .flag:before, .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -36px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 4px solid rgb(255,80,80);
    z-index: 10;
}
  
.direction-r .flag:before {
    left: -36px;
}
  
.direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
}
  
.direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
}
  
.time-wrapper {
    display: inline;
    
    line-height: 1em;
    font-size: 0.66666em;
    color: rgb(250,80,80);
    vertical-align: middle;
}
  
.direction-l .time-wrapper {
    float: left;
}
  
.direction-r .time-wrapper {
    float: right;
}
  
.time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
}
  
.desc {
    /* margin: 1em 0.75em 0 0; */
    
    font-size: 0.77777em;
    font-style: italic;
    line-height: 1.5em;
}
  
.direction-r .desc {
    margin: 1em 0 0 0.75em;
}
  
  /* ================ Timeline Media Queries ================ */
  
@media screen and (max-width: 660px) {
  
    .timeline {
        width: 100%;
        padding: 4em 0 1em 0;
    }
  
    .timeline li {
        padding: 2em 0;
    }
  
    .direction-l, .direction-r {
        float: none;
        width: 100%;
        text-align: center;
    }
  
    .flag-wrapper {
        text-align: center;
    }
  
    .flag {
        background: rgb(255,255,255);
        z-index: 15;
    }

    .direction-l .flag:before,
    .direction-r .flag:before {
        position: absolute;
        top: -30px;
        left: 50%;
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        margin-left: -9px;
        background: #fff;
        border-radius: 10px;
        border: 4px solid rgb(255,80,80);
        z-index: 10;
    }

    .direction-l .flag:after, .direction-r .flag:after {
        content: "";
        position: absolute;
        left: 50%;
        top: -8px;
        height: 0;
        width: 0;
        margin-left: -8px;
        border: solid transparent;
        border-bottom-color: rgb(255,255,255);
        border-width: 8px;
        pointer-events: none;
    }

    .time-wrapper {
        display: block;
        position: relative;
        margin: 4px 0 0 0;
        z-index: 14;
    }
  
    .direction-l .time-wrapper {
        float: none;
    }
  
    .direction-r .time-wrapper {
        float: none;
    }
  
    .desc {
        position: relative;
        margin: 1em 0 0 0;
        padding: 1em;
        background: rgb(245,245,245);
        -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
        -moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
        box-shadow: 0 0 1px rgba(0,0,0,0.20);

        z-index: 15;
    }
  
    .direction-l .desc, .direction-r .desc {
        position: relative;
        margin: 1em 1em 0 1em;
        padding: 1em;

        z-index: 15;
    }
}

.cursor {
    display: inline-block;
    position: relative;
}

.cursor::after {
    content: "";
    margin: auto;
    position: absolute;
    right: -4px;
    width: 2px;
    height: 100%;
    background-color: #FFFFFF;
    animation: cursor-animation 1.5s step-end infinite;
}

@keyframes cursor-animation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}